.App {
  text-align: center;
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */
/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.head-text {
  position: relative;
  text-align: center;
  padding-top: 25px;
}
.image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.text-on-image {
  color: white;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
}